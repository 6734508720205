<template>
  <div class="message-page" id="print">
    <v-main>
      <v-container>
        <v-overlay :value="overlay" color="white" opacity="1">
          <div class="spinner-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </v-overlay>

        <v-row align="center" justify="center">
          <v-col cols="12" lg="7">
            <div class="d-block text-center mt-16 logo_gifty">
              <img alt="logo" src="@/assets/logo_gifty.svg" width="120" />
            </div>

            <div v-if="error">
              <v-card class="rounded-lg shadow mt-5">
                <v-card-text class="text-center pa-6">
                  <v-icon color="red" size="80">mdi-alert-circle</v-icon>

                  <h2 class="my-5">
                    Erreur
                    <small class="red--text">{{ responseCode }} </small> !!
                  </h2>

                  <p class="pa-2 f-17 card-text">
                    {{ error }}<br />
                    <span
                      class="gifty--text text-decoration-underline font-weight-medium pointer"
                      @click="confirmOrder"
                    >
                      Rafraîchir la page
                    </span>
                    <br />
                    Ou contacter
                    <a
                      class="gifty--text font-weight-medium"
                      href="https://gifty-dz.com/#contact"
                      target="_blank"
                      >le support.
                    </a>
                  </p>
                </v-card-text>
              </v-card>
            </div>

            <div v-else>
              <v-card v-if="isShowLoading" class="rounded-lg shadow mt-5">
                <v-card-text class="text-center pa-6">
                  <v-skeleton-loader type="avatar,article,actions" />
                </v-card-text>
              </v-card>

              <v-card v-else class="rounded-lg shadow mt-5">
                <v-card-text class="text-center">
                  <v-icon
                    v-if="epayment"
                    :color="epayment?.status?.color"
                    size="80"
                  >
                    {{
                      epayment.status.color == "success"
                        ? "mdi-check-circle"
                        : "mdi-alert-circle"
                    }}
                  </v-icon>
                  <v-icon color="error" size="80" v-else>
                    {{ "mdi-alert-circle" }}
                  </v-icon>

                  <h2 class="my-5" style="line-height: 31px">
                    {{ epayment.message }}
                  </h2>
                  <div v-if="epayment && epayment.status.color == 'success'">
                    <p class="pa-2 f-17 card-text">
                      Salut,
                      <strong> {{ epayment?.account?.user.name }} </strong>
                      ! Ceci est un message de confirmation de votre paiement.
                    </p>

                    <p class="pa-2 f-17 card-text">
                      Une copie d'une facture détaillée peut être trouvée ici
                    </p>

                    <v-simple-table
                      class="table-border mb-5 text-left"
                      v-if="epayment"
                    >
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            
                            <td
                              class="font-weight-medium text-right"
                              style="width: 50% !important"
                            >
                              Nom complet:
                            </td>
                            <td class="font-weight-bold" style="width: 50% !important">
                              {{ epayment?.transaction?.account?.user?.name  }}
                              {{ epayment?.voucher_order?.account?.user?.name  }}
                            </td>
                          </tr>
                          <tr>
                            
                            <td
                              class="font-weight-medium text-right"
                              style="width: 50% !important"
                            >
                              Identifiant de la transaction:
                            </td>
                            <td class="font-weight-bold">
                              {{ epayment.getway_order_id }}
                            </td>
                          </tr>
                          <tr>
                            <td class="font-weight-medium text-right">
                              Numéro de commande:
                            </td>
                            <td class="font-weight-bold">{{ epayment.id }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-medium text-right">
                              Numéro d'autorisation:
                            </td>
                            <td class="font-weight-bold">{{ epayment.code }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-medium text-right">Date:</td>
                            <td class="font-weight-bold">
                              {{ epayment.created_at }}
                            </td>
                          </tr>

                          <tr>
                            <td class="font-weight-medium text-right">
                              Moyen de paiement:
                            </td>
                            <td class="font-weight-bold">
                              Carte CIB & Edahabia
                            </td>
                          </tr>

                          <tr>
                            <td class="font-weight-medium text-right">Type:</td>
                            <td class="font-weight-bold">
                              {{ epayment.transaction ? "Consommation" : "" }}
                              {{
                                epayment.voucher_order
                                  ? "Commander un voucher"
                                  : ""
                              }}
                            </td>
                          </tr>

                          <tr>
                            <td class="font-weight-medium text-right">
                              Service:
                            </td>
                            <td class="font-weight-bold">
                              {{
                                epayment.transaction
                                  ? epayment.transaction.service.name
                                  : ""
                              }}
                              {{
                                epayment.voucher_order
                                  ? epayment.voucher_order.service.name
                                  : ""
                              }}
                            </td>
                          </tr>

                          <tr>
                            <td class="font-weight-medium text-right">
                              Montant Service:
                            </td>
                            <td class="font-weight-bold pink--text darken-4">
                              {{
                                epayment.transaction
                                  ? CurrencyFormatting(
                                      epayment.transaction.amount
                                    )
                                  : ""
                              }}
                              {{
                                epayment.voucher_order
                                  ? CurrencyFormatting(
                                      epayment.voucher_order.amount
                                    )
                                  : ""
                              }}
                              DZD
                            </td>
                          </tr>

                          <tr>
                            <td class="font-weight-medium text-right">
                              <h3>Total:</h3>
                            </td>
                            <td class="font-weight-bold success--text">
                              <h3>
                                {{ CurrencyFormatting(epayment.amount) }} DZD
                              </h3>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                    <div class="noprint">
                      <v-btn
                      block
                      class="text-none mb-3"
                      large
                      outlined
                      text
                      @click="printPage"
                    >
                      <v-icon color="red lighten-1" left size="25"
                        >mdi-printer</v-icon
                      >
                      Imprimer
                    </v-btn>

                    <v-btn
                      block
                      class="text-none"
                      large
                      outlined
                      text
                      @click="downloadInvoice"
                    >
                      <v-icon color="red lighten-1" left size="25"
                        >mdi-file-pdf-box</v-icon
                      >
                      Télécharger en PDF
                    </v-btn>

                    <v-btn
                      block
                      class="text-none mt-3"
                      large
                      outlined
                      text
                      @click="
                        [
                          (dialog = true),
                          (errors = {}),
                          (successMessage = false),
                        ]
                      "
                    >
                      <v-icon color="red lighten-1" left size="25"
                        >mdi-email-send</v-icon
                      >
                      Envoyer par e-mail
                    </v-btn>
                  </div>
                    </div>

                    

                  <div v-else></div>

                  <v-btn
                    block
                    class="text-none mt-3 noprint "
                    large
                    outlined
                    text
                    @click="goToApk()"
                  >
                    <v-icon color="red lighten-1" left size="25"
                      >mdi-arrow-left-thick</v-icon
                    >
                    Revenir à l'application
                  </v-btn>
                </v-card-text>
              </v-card>
            </div>

            <div class="d-block text-center mt-16">
              <img alt="logo" src="@/assets/green_number.png" width="200" />
            </div>
            

            <p class="pt-10 text-center">
              © Copyright {{ new Date().getFullYear() }}
              <a
                class="text-primary"
                href="https://gifty-dz.com/"
                target="_blank"
                >Gifty Algérie</a
              >
              Tous droits réservés.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-dialog v-model="dialog" max-width="450" persistent>
      <v-card :disabled="btnLoading" :loading="btnLoading">
        <v-card-title>
          Envoyer par e-mail
          <v-spacer />
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider />

        <v-card-text class="pa-8">
          <v-alert v-if="successMessage" border="left" text type="success">
            La facture a été envoyée à votre adresse e-mail avec succès.
          </v-alert>

          <p class="font-weight-medium">
            Entrer votre email <span class="red--text">*</span>
          </p>

          <v-text-field
            v-model="email"
            :error-messages="errors.email"
            color="gifty"
            outlined
            placeholder="Entrer votre email ici..."
            @input="errors.email = null"
          />

          <v-btn
            :loading="btnLoading"
            block
            color="gifty"
            dark
            depressed
            large
            @click="sendInvoiceEmail"
          >
            Envoyer
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      isShowLoading: false,
      overlay: false,
      btnLoading: false,
      dialog: false,
      error: null,
      email: null,
      successMessage: false,
      errors: {},
      epayment: {},
      responseCode: null,
    };
  },
  methods: {
    showOrder() {
      this.isShowLoading = true;
      let token = this.$route.params.token;
      axios
        .get(
          process.env.VUE_APP_BASE_URL + "v1/e-payments/origin-order/" + token
        )
        .then((res) => {
          this.isShowLoading = false;
          this.epayment = res.data;
        })
        .catch((err) => {
          this.isShowLoading = false;
          console.log(err);
        });
    },
    confirmOrder() {
      // this.overlay = true
      // axios.post(process.env.VUE_APP_BASE_URL + 'v1/e-payments/confirm', {
      //     token: this.$route.params.token,
      // }).then(res => {
      //     this.overlay = false
      //     this.showOrder()
      // }).catch(err => {
      //     this.overlay = false;
      //     if(err.response.status == 401) { // from satim
      //       this.responseCode = err.response.data.data.responseCode
      //       this.error = "Une erreur s'est produit, veuillez";
      //     }else{
      //       this.error = err.response.data.message;
      //     }
      //     console.log(err)
      // })
    },
    downloadInvoice() {
      let token = this.$route.params.token;
      window.open(
        process.env.VUE_APP_BASE_URL +
          "v1/e-payments/invoice/download-pdf?token=" +
          token
      );
    },
    sendInvoiceEmail() {
      this.successMessage = false;
      this.btnLoading = true;
      this.errors = {};
      axios
        .post(
          process.env.VUE_APP_BASE_URL + "v1/e-payments/invoice/send-email",
          {
            email: this.email,
            token: this.$route.params.token,
          }
        )
        .then(() => {
          this.btnLoading = false;
          this.successMessage = true;
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.btnLoading = false;
          console.log(err);
        });
    },
    call() {
      window.open("tel:3020", "_self");
    },
    goToApk() {
      window.open(process.env.VUE_APP_gifty_deep_link, "_blank");
    },

    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("app").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      stylesHtml +='<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@mdi/font@7.2.96/css/materialdesignicons.min.css">';

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
  },
  created() {
    //this.confirmOrder()
    this.showOrder();
  },
};
</script>
<style>
.card-text {
  line-height: 1.9;
}

@media print{
   .noprint{
       display:none;
   }
   .mt-16{
    margin-top: 0px !important;
   }
}
</style>